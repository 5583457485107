<template>
  <div>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="4">
          <base-material-card
            class="v-card-profile"
            avatar="/HRvecfiles/logo.png"
          >
            <!-- <base-material-card
          class="v-card-profile"
          avatar="/HRvecfiles/logo.png"    
         
        > -->
            <v-card-text class="text-center">
              <div align="center">
                <!-- <img v-if="users.assistantteach_pic"    
                width="300"                   
                    style="border-radius: 8px"
                    :src="'/HRvecfiles/' + users.assistantteach_pic"                    
                  /> 
                  <img v-else
                   width="200" 
                    src="/HRvecfiles/blank-human-image.png"
                    style="border-radius: 30px"
                    /> -->
                <img
                  width="200"
                  src="/HRvecfiles/blank-human-image.png"
                  style="border-radius: 30px"
                />
              </div>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.idCard }}
              </h3>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.titles }}{{ users.names }} {{ users.lastname }}
              </h3>
              <!--  <h4 class="font-weight-light mb-3 black--text">
                {{ users.college_name }}
              </h4> -->
              <p class="font-weight-light grey--text"></p>

              <!--  <div>
                <v-btn
                  elevation="2"
                  x-large
                  rounded
                  color="warning"
                  class="mr-0"
                  @click.native="passwordUpdate()"
                >
                  <v-icon>mdi-pencil</v-icon>
                  แก้ไขรหัสผ่านเข้าสู่ระบบ</v-btn
                >
              </div> -->
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="12" md="8">
          <base-material-card class="text_google">
            <template v-slot:heading>
              <div class="font-weight-light text_google">
                <h2 class="h1 font-weight-light text_google">
                  <v-icon large left>mdi-account</v-icon> ข้อมูลส่วนบุคคล
                </h2>
              </div>
            </template>
            <v-card class="elevation-6" width="100%">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-list two-line outlined>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>สังกัด</v-list-item-title>
                            <v-list-item-subtitle>
                              <h3>
                                {{ users.college_name }}
                              </h3></v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="users.assistantCollege">
                          <v-list-item-content>
                            <div class="text-center"></div>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-else>
                          <v-list-item-content>
                            <div class="text-center">
                              <v-btn
                              block
                                elevation="2"
                                x-large
                                rounded
                                color="info"
                                class="mr-0"
                                @click.native="passistantteachUpdate()"
                              >
                                <v-icon>mdi-domain</v-icon>
                                สถานศึกษาที่เลือก</v-btn
                              >
                            </div>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-school
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>คุณวุฒิ</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.couses
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-school
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>สาขาวิชาเอก</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.branchs
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list two-line outlined>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-box
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >รหัสบัตรประชาชน</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              users.idCard
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action></v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title
                              >วันเดือนปี เกิด</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ users.birthdays }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-settings
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >เบอร์โทรติดต่อ</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              users.tels
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-settings
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>สถานภาพสมรส</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ users.statusMarried }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-settings
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>E-mail</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.emails
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <v-alert prominent type="error" v-if="users.assistantCollege">
                <v-row align="center">
                  <v-col class="grow">
                    เมื่อเลือกสถานศึกษาแล้วไม่สามารถแก้ไขเปลี่ยนแปลงได้
                    กรุณาติดต่อผู้ดูแลระบบ
                  </v-col>
                </v-row>
              </v-alert>
               <v-alert prominent type="warning" v-else>
                <v-row align="center">
                  <v-col class="grow">
                 <h4>เลือกสถานศึกษาตามที่ได้ประสงค์เลือกตามลำดับ โดยยึดตามเอกสารแสดงความจำนงเลือกเป็นหลัก</h4>  
                  </v-col>
                </v-row>
              </v-alert>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>

      <!-- V-model userdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="addpassistantteachdialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="info"
              icon="mdi-clipboard-text"
              title="แสดงความจำนงเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย"
              class="px-5 py-3 text_google"
              elevation="2"
            >
              <div align="right">
                {{ users.idCard }} {{ users.titles }}{{ users.names }}
                {{ users.lastname }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="passistantteachform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.assistantCollege"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex>
                      <div class="red--text">
                        *กรุณาเลือกสถานศึกษาให้ตรงกับความเป็นจริง
                        หากตรวจสอบแล้วพบว่าไม่ถูกต้อง
                        แสดงว่าท่านมีเจตนาไม่พึงประสงค์
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="addpassistantteachdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="ConfirmassistantteachSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon>&nbsp;เลือก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model confirmassistantteachdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="confirmassistantteachdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="info"
              icon="mdi-clipboard-text"
              title="แสดงความจำนงเลือกสถานศึกษาเพื่อเข้าบรรจุและแต่งตั้ง ตำแหน่งครูผู้ช่วย"
              class="px-5 py-3 text_google"
              elevation="2"
            >
              <div align="right">
                {{ users.idCard }} {{ users.titles }}{{ users.names }}
                {{ users.lastname }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="confirmassistantteachdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h4 class="warning--text">แสดงความจำนงเลือก</h4>
                      <h2 class="red--text">
                        {{ collegeselect.college_name }}
                      </h2>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="confirmassistantteachdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="passistantteachSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model passworddialog -->
      <v-layout row justify-center>
        <v-dialog v-model="passwoorddialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขรหัสผ่าน"
              class="px-5 py-3 text_google"
              elevation="2"
            >
              <div align="right">
                {{ users.idCard }} {{ users.titles }}{{ users.names }}
                {{ users.lastname }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="passwordform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Password"
                        v-model="users.p_word"
                        type="password"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Confirm Password"
                        v-model="users.user_confirmpassword"
                        type="password"
                        :rules="[v => v == users.p_word]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="passwoorddialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="passwordSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      period_enable: "1",
      addpassistantteachdialog: false,
      assistantteach_pic_dialog: false,
      deletefiledialog: false,
      passwoorddialog: false,
      confirmassistantteachdialog: false,
      transference_passistantteachs: [],
      valid: true,
      user: {},
      users: [],
      updateuser: {},
      passistantteach: {},
      periods: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      provices_sh: [],
      user_marital_status: [
        { title: "โสด", value: "single" },
        { title: "สมรส", value: "married" },
        { title: "หม้าย", value: "widow" },
        { title: "หย่า", value: "divorce" },
        { title: "แยกกันอยู่", value: "separate" }
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      headers_teach: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "tid_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "idCard" },
        { text: "ชื่อ", align: "center", value: "names" },
        { text: "นามสกุล", align: "center", value: "lastname" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" }
        /*  { text: "ความคิดเห็นผู้อำนวยการ", align: "center", value: "actions" } */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      colleges: [],
      collegeselect: [],
      assistantCollege: ""
    };
  },
  async mounted() {
    await this.assistantteachQuery();
    await this.collegeQueryAll();
  },

  methods: {
    async assistantteachQuery() {
      let result;
      this.loading = true;
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http
        .post("assistantteach.php", {
          ApiKey: this.ApiKey,
          idCard: useression.idCard
        })
        .finally(() => (this.loading = false));
      this.users = result.data;
    },

    async collegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.colleges = result.data;
    },

    async passistantteachUpdate() {
      this.users.p_word = "";
      this.addpassistantteachdialog = true;
    },

    async ConfirmassistantteachSubmit() {
      let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey,
          college_code: this.users.assistantCollege
        })
        .finally(() => (this.loading = false));
      this.collegeselect = result.data;
      this.confirmassistantteachdialog = true;
    },

    async passistantteachSubmit() {
      if (this.$refs.passistantteachform.validate()) {
        this.users.ApiKey = this.ApiKey;
        if (this.users.p_word == "") delete this.users.p_word;
        let result = await this.$http.post(
          "assistantteach.update.php",
          this.users
        );
        if (result.data.status == true) {
           Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.assistantteachQuery();
        } else {
          Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
        }
        this.confirmassistantteachdialog = false;
        this.addpassistantteachdialog = false;
      }
    }

    /* async passwordSubmit() {
      if (this.$refs.passwordform.validate()) {
        this.users.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "assistantteach.update.php",
          this.user
        );
        if (result.data.status == true || result_m.data.status == true) {
          this.user = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการแก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
           this.assistantteachQuery() 
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.passwoorddialog = false;
      }
    },    */
  },
  computed: {}
};
</script>
<style>
v-img {
  border-radius: 8px;
}
</style>
